import logo_amatic from "../../assets/logo_amatic.png";
import logo_1xbet from "../../assets/logo_1xbet.png";
import logo_yggdrasil from "../../assets/logo_yggdrasil.png";
import logo_evolution_gaming from "../../assets/logo_evolution-gaming.png";
import logo_ezugi from "../../assets/logo_ezugi.png";
import logo_bet_games_tv from "../../assets/logo_bet-games-tv.png";
import logo_egt from "../../assets/logo_egt.png";
import logo_inbet from "../../assets/logo_inbet.png";
import logo_wazdan from "../../assets/logo_wazdan.png";

export const Footer = (props) => {
	return (
		<div className="footer">
			<div className="footer__top">
				<ul className="footer__partners partners">
					<li className="partners__item"><img className="partners__logo" src={logo_amatic} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_1xbet} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_yggdrasil} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_evolution_gaming} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_ezugi} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_bet_games_tv} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_egt} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_inbet} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_wazdan} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_ezugi} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_bet_games_tv} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_egt} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_amatic} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_1xbet} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_yggdrasil} alt=""/></li>
					<li className="partners__item"><img className="partners__logo" src={logo_evolution_gaming} alt=""/></li>
				</ul>
			</div>
			<div className="footer__bottom">
				<p className="copyright">{props.text}</p>
			</div>
		</div>
	)
}