import React, {useEffect, useState} from "react";
import { osName } from 'react-device-detect';
import {useRatio} from "../../hooks/useRatio";
import button_download_desktop from "../../assets/button-download_desktop.png";
import button_download_desktop_x2 from "../../assets/button-download_desktop_x2.png";
import button_download_mobile from "../../assets/button-download_mobile.png";
import button_download_mobile_x2 from "../../assets/button-download_mobile_x2.png";
import button_play_desktop from "../../assets/button-play_desktop.png";
import button_play_desktop_x2 from "../../assets/button-play_desktop_x2.png";
import button_play_mobile from "../../assets/button-play_mobile.png";
import button_play_mobile_x2 from "../../assets/button-play_mobile_x2.png";
import icon_download from "../../assets/icon_download.svg";
import icon_win from "../../assets/icon_win.svg";
import icon_ios from "../../assets/icon_ios.svg";
import icon_android from "../../assets/icon_android.svg";
import icon_play from "../../assets/icon_play.svg";
import icon_chrome from "../../assets/icon_chrome.svg";
import icon_safari from "../../assets/icon_safari.svg";


export const Action = (props) => {
	const [playLink, setPlayLink] = useState("")
	const [downloadLink, setDownloadLink] = useState("")

	useEffect(() => {
		fetch("./data/settings.json")
			.then(request => request.json())
			.then((data) => {
				switch(osName) {
					case 'Android':
						setPlayLink(data.android.play_link)
						setDownloadLink(data.android.download_link)
						break
					case 'iOS':
						setPlayLink(data.ios.play_link)
						setDownloadLink(data.ios.download_link)
						break
					default:
						setPlayLink(data.desktop.play_link)
						setDownloadLink(data.desktop.download_link)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	},[])

	const ratio = useRatio()

	return (
		<div className={`action action_${props.type}`}>
			{props.type === "download" &&
				<a href={downloadLink} className="action__btn">
					{ratio &&
					<picture>
						<source media="(min-width: 1024px)" srcSet={ratio >= 2 ? button_download_desktop_x2 : button_download_desktop}/>
						<img
							src={ratio >= 2 ? button_download_mobile_x2 : button_download_mobile}
							className='action__btn-body'
							alt={props.type}
						/>
					</picture>
					}
					<span className="action__btn-inner">
            <img src={icon_download} className="action__btn-icon" alt=""/>
            <span className="action__btn-title-desktop">{props.title}</span>
          </span>
				</a>
			}

			{props.type === "play" &&
				<a href={playLink} className="action__btn">
					{ratio &&
					<picture>
						<source media="(min-width: 1024px)" srcSet={ratio >= 2 ? button_play_desktop_x2 : button_play_desktop}/>
						<img
							src={ratio >= 2 ? button_play_mobile_x2 : button_play_mobile}
							className='action__btn-body'
							alt=""
						/>
					</picture>
					}
					<span className="action__btn-inner">
	          <img src={icon_play} className="action__btn-icon" alt=""/>
	           <span className="action__btn-title-desktop">{props.title}</span>
	        </span>
				</a>
			}

			<div className="action__desc">
				<h3 className="action__title">{props.title}</h3>
				<p className="action__platforms">{props.platforms}</p>

				{props.type === "download" &&
					<ul className="action__icons">
						<li><img src={icon_win} alt="windows"/></li>
						<li><img src={icon_ios} alt="ios"/></li>
						<li><img src={icon_android} alt="android"/></li>
					</ul>
				}

				{props.type === "play" &&
				<ul className="action__icons">
					<li><img src={icon_chrome} alt="windows"/></li>
					<li><img src={icon_safari} alt="ios"/></li>
				</ul>
				}
			</div>
		</div>
	)
}