import React from "react";
import {useRatio} from "../../hooks/useRatio";

export const Visual = (props) => {

	const ratio = useRatio()

	return (
		<>
			<div className="visual-back">
				{props.isDeviceLoad && ratio &&
				<div className="visual-back__inner" style={{width: props.width, height: props.height, top: props.yPos}}>
					<picture>
						<source media="(max-width: 1499px)" srcSet={ratio >= 2 ? require("../../assets/airship_x2.png") : require("../../assets/airship.png")}/>
						<img src={require("../../assets/empty.png")} className='visual-back__airship' alt=""/>
					</picture>

					<picture>
						<img src={ratio >= 2 ? require("../../assets/money_x2.png") : require("../../assets/money.png")} className='visual-back__money2' alt=""/>
					</picture>

					<picture>
						<source media="(orientation: landscape)" srcSet={ratio >= 2 ? require("../../assets/gold_x2.png") : require("../../assets/gold.png")}/>
						<img src={require("../../assets/empty.png")} className='visual-back__gold' alt=""/>
					</picture>

					<picture>
						<source media="(min-width: 1500px)" srcSet={ratio >= 2 ? require("../../assets/cat_desktop_x2.png") : require("../../assets/cat_desktop.png")}/>
						<source media="(min-width: 1024px)" srcSet={ratio >= 2 ? require("../../assets/pig_tablet_x2.png") : require("../../assets/pig_tablet.png")}/>
						<img src={ratio >= 2 ? require("../../assets/pig_x2.png") : require("../../assets/pig.png")} className='visual-back__pig' alt=""/>
					</picture>

					<picture>
						<source media="(min-width: 1500px)" srcSet={ratio >= 2 ? require("../../assets/diamond2_x2.png") : require("../../assets/diamond2.png")}/>
						<img src={require("../../assets/empty.png")} className='visual-back__diamond2' alt=""/>
					</picture>

					<picture>
						<source media="(min-width: 1500px)" srcSet={ratio >= 2 ? require("../../assets/diamond1_x2.png") : require("../../assets/diamond1.png")}/>
						<img src={require("../../assets/empty.png")} className='visual-back__diamond1' alt=""/>
					</picture>

					<picture>
						<source media="(min-width: 1500px)" srcSet={ratio >= 2 ? require("../../assets/woman_desktop_x2.png") : require("../../assets/woman_desktop.png")}/>
						<source media="(min-width: 1024px)" srcSet={ratio >= 2 ? require("../../assets/woman_tablet_x2.png") : require("../../assets/woman_tablet.png")}/>
						<img src={ratio >= 2 ? require("../../assets/woman_mobile_x2.png") : require("../../assets/woman_mobile.png")} className='visual-back__woman' alt=""/>
					</picture>
				</div>
				}
			</div>

			<div className="visual-back visual-back_front">
				<div className="visual-back__inner" style={{width: props.width, height: props.height, top: props.yPos}}>
					{ratio &&
					<picture>
						<source media="(min-width: 1024px)" srcSet={ratio >= 2 ? require("../../assets/money_x2.png") : require("../../assets/money.png")}/>
						<source media="(orientation: landscape)" srcSet={require("../../assets/empty.png")}/>
						<img src={ratio >= 2 ? require("../../assets/money_x2.png") : require("../../assets/money.png")} className='visual-back__money1' alt=""/>
					</picture>
					}
				</div>
			</div>
		</>
	)
}