import {useEffect, useState} from "react";

export const useRatio = () => {
	const [ratio, setRatio] = useState(null)
	useEffect(() => {
		let winRatio = window.devicePixelRatio
		if (winRatio) {
			setRatio(winRatio)
		}
	},[])
	return ratio
}