import React, {useEffect, useRef, useState} from "react";
import {useRatio} from "./hooks/useRatio";
import {Pixelperfect} from "./utils/Pixelperfect/Pixelperfect";
import {Header} from "./components/Header/Header";
import {Footer} from "./components/Footer/Footer";
import {Action} from "./components/Action/Action";
import {Visual} from "./components/Visual/Visual";
import "./styles/App.scss";

function App() {
  const [lang, setLang] = useState("en")
  const [isDeviceLoad, setIsDeviceLoad] = useState(false)
  const [text, setText] = useState({
    header: "",
    button_play: "",
    button_download: ""
  })

  const setWinHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  window.addEventListener('resize', () => {
    setWinHeight()
  });

  useEffect(() => {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang) {
      setLang(userLang.split("-")[0].toLowerCase())
    }

    setWinHeight()
  },[])

  const ratio = useRatio()

  useEffect(() => {
    fetch("./data/langs.json")
      .then(request => request.json())
      .then((data) => {
        data.find((x) => {
          if (x.lang === lang) {
            setText({
              header: x.data.header,
              button_play: x.data.button_play,
              button_download: x.data.button_download
            })
          }
        })
      })
      .catch((e) => {
        console.log(e)
      })
  },[lang])

  const refImage = useRef(null)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [yPos, setYPos] = useState(0)

  const visualBackSet = () => {
    if (refImage && refImage.current && text.header.length > 0) {
      const {y} = refImage.current.getBoundingClientRect()
      setYPos(y)
      setHeight(refImage.current.clientHeight)
      setWidth(refImage.current.clientWidth)
    }
  }

  useEffect(() => {
    visualBackSet()
  },[refImage, text])

  window.addEventListener("resize", () => {
    window.location.reload();
    visualBackSet()
  })

  window.addEventListener("orientationchange", function() {
    window.location.reload();
    visualBackSet()
  })

  const onLoadChange = () => {
    //window.location.reload();
    visualBackSet()
    setIsDeviceLoad(true)
  }

  return (
    <div className="app">
      {/*<Pixelperfect/>*/}
      <Header text={text.header} />

      <Visual height={height} width={width} yPos={yPos} isDeviceLoad={isDeviceLoad}/>

      <div className="primary">
        <div className="primary__inner">
          <div className="visual">
            <div className="money">
              <img
                src={ratio >= 2 ? require("./assets/money_inner_x2.png") : require("./assets/money_inner.png")}
                alt=""
                className="money__inner"
              />
              <img
                src={ratio >= 2 ? require("./assets/money_figures_x2.png") : require("./assets/money_figures.png")}
                alt=""
                className="money__figures"
              />
              <img
                src={ratio >= 2 ? require("./assets/money_ring_x2.png") : require("./assets/money_ring.png")}
                alt=""
                className="money__ring"
              />
              <img
                src={ratio >= 2 ? require("./assets/money_text_x2.png") : require("./assets/money_text.png")}
                alt=""
                className="money__text"
              />
            </div>
            
            {ratio &&
            <picture className="visual__picture-inner">
              <source media="(orientation: landscape)" srcSet={require("./assets/device_phone_horiz.png")}/>
              <img
                ref={refImage}
                src={require("./assets/device_phone_vert.png")}
                alt=""
                className="visual__device"
                onLoad={onLoadChange}
              />
            </picture>
            }
          </div>

          <div className="primary__actions">
            {ratio &&
            <picture>
              <source media="(orientation: landscape) and (max-width: 1023px)" srcSet={ratio >= 2 ? require("./assets/money_x2.png") : require("./assets/money.png")}/>
              <img src={require("./assets/empty.png")} className='primary__actions-money' alt=""/>
            </picture>
            }
           <div className="primary__actions-inner">
             <Action type="download" title={text.button_download} platforms="win/iOS/android" />
             <Action type="play" title={text.button_play} platforms="chrome/safari" />
           </div>
         </div>
        </div>
      </div>
      <Footer text="© VEGAS DREAM. 2022"/>
    </div>
  );
}

export default App;
