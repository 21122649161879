import React from "react";
import {useRatio} from "../../hooks/useRatio";

export const Header = (props) => {
	const ratio = useRatio()
	return (
		<div className="header">
			{ratio &&
				<picture>
					<source media="(orientation: landscape)" srcSet={ratio >= 2 ? require("../../assets/logo_landscape_x2.png") : require("../../assets/logo_landscape.png")}/>
					<img src={ratio >= 2 ? require("../../assets/logo_portrait_x2.png") : require("../../assets/logo_portrait.png")} className='header__logo' alt=""/>
				</picture>
			}
			<p className='header__desc'>{props.text}</p>
		</div>
	)
}